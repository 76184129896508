import React from 'react'
import { createProfile, deleteProfile } from '../../actions/profiles';
import { TrashIcon } from '@primer/octicons-react';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        uuid: this.props.profile.uuid,
        domain: this.props.profile.domain,
        display_name: this.props.profile.display_name,
        description: this.props.profile.description,
        website: this.props.profile.website,
        phone_number: this.props.profile.phone_number,
        country: this.props.profile.country,
        public: this.props.profile.public,
        avatar_path: this.props.profile.avatar_path,
      }
    }

    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.handlePublicChange = this.handlePublicChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleProfileChange(event) {
    const updatedProfile = this.state.profile;

    updatedProfile[event.target.name] = event.target.value;

    this.setState({ profile: updatedProfile });
  }

  handlePublicChange(event) {
    const updatedProfile = this.state.profile;

    updatedProfile['public'] = !updatedProfile.public

    this.setState({ profile: updatedProfile });
  }


  handleSubmit(event) {
    event.preventDefault();
    const { user } = this.props;

    this.props.dispatch(createProfile(user.uuid, this.state.profile));
  }

  handleDelete(event) {
    event.preventDefault();
    const { dispatch } = this.props;

    dispatch(deleteProfile(this.state.profile));
  }

  render() {
    const { profile } = this.props;

    return (
      <div className="border">
        <div className="bg-gray-100 p-2 rounded font-bold border-b text-sm flex justify-between">
          <h3>{ profile.domain }</h3>
          <button onClick={this.handleDelete}>
            <TrashIcon />
          </button>
        </div>
        <div className="p-2">
          <div>
            <label className="block text-xs font-bold uppercase text-gray-700">
              Display Name
            </label>
            <input
              value={this.state.profile.display_name}
              name="display_name"
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleProfileChange}
              type="text" />
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Description
            </label>
            <textarea
              value={this.state.profile.description}
              name="description"
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleProfileChange}>
            </textarea>
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Website
            </label>
            <input
              value={this.state.profile.website}
              name="website"
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleProfileChange}
              type="text" />
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Phone Number
            </label>
            <input
              value={this.state.profile.phone_number}
              name="phone_number"
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleProfileChange}
              type="text" />
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Country
            </label>
            <select
              name="country"
              value={this.state.profile.country}
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none bg-transparent"
              onChange={this.handleProfileChange}>
                <option label=" "></option>
                <option value="DE">DE</option>
                <option value="AT">AT</option>
                <option value="CH">CH</option>
                <option value="LU">LU</option>
                <option value="IT">IT</option>
                <option value="ES">ES</option>
            </select>
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Avatar path
            </label>
            <input
              value={this.state.profile.avatar_path}
              name="avatar_path"
              className="mt-1 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleProfileChange}
              type="text" />
          </div>
          <div className="my-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Public
            </label>
              <input
                type="checkbox"
                checked={this.state.profile.public}
                onChange={this.handlePublicChange} />
          </div>
          <button
            onClick={this.handleSubmit}
            className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
            Update
          </button>
        </div>
      </div>
    );
  }
}

export default Profile;
