// AUTH
export const AUTH_REQUESTED = 'AUTH_REQUESTED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DISMISS = 'AUTH_DISMISS';

// USERS
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_DELETE = 'USER_DELETE';

// PROFILES
export const PROFILES_SUCCESS = 'PROFILES_SUCCESS';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS';
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS';

// COURSES
export const COURSES_SUCCESS = 'COURSES_SUCCESS';
export const COURSE_SUCCESS = 'COURSE_SUCCESS';
export const COURSE_DELETE = 'COURSE_DELETE';

// COURSES
export const MODULES_SUCCESS = 'MODULES_SUCCESS';
export const MODULE_SUCCESS = 'MODULE_SUCCESS';
export const MODULE_DELETE = 'MODULE_DELETE';

// ATTACHMENTS
export const ATTACHMENT_CREATE = 'ATTACHMENT_CREATE';
export const ATTACHMENT_DELETE = 'ATTACHMENT_DELETE';
export const ATTACHMENT_UPLOAD = 'ATTACHMENT_UPLOAD';

// ACCESSES
export const ACCESSES_SUCCESS = 'ACCESSES_SUCCESS';
export const ACCESS_SUCCESS = 'ACCESS_SUCCESS';
export const ACCESS_DELETE = 'ACCESS_DELETE';
export const ACCESS_UPDATE = 'ACCESS_UPDATE';

// PRODUCTS
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';

// PAYMENTS
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';

// MESSAGES
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_DELETE = 'MESSAGE_DELETE';
export const MESSAGE_REQUEST = 'MESSAGE_REQUEST';
export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';

// CAMPAIGN
export const CAMPAIGNS_SUCCESS = 'CAMPAIGNS_SUCCESS';
export const CAMPAIGN_SUCCESS = 'CAMPAIGN_SUCCESS';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE';

// COURSE CAMPAIGN
export const COURSE_CAMPAIGNS_SUCCESS = 'COURSE_CAMPAIGNS_SUCCESS';
export const COURSE_CAMPAIGN_SUCCESS = 'COURSE_CAMPAIGN_SUCCESS';
export const COURSE_CAMPAIGN_DELETE = 'COURSE_CAMPAIGN_DELETE';
export const COURSE_CAMPAIGN_UPDATE = 'COURSE_CAMPAIGN_UPDATE';
