import React from 'react'
import { connect } from 'react-redux';
import DeleteCampaignButton from './DeleteCampaignButton';

class Campaign extends React.Component {
  render() {
    const { campaign, attachments, modules } = this.props;

    const attachmentName = (id) => {
      const attachment = attachments.find(a => a.uuid === id);

      if (!attachment) {
        return "-";
      }

      const mod = modules.find(m => m.uuid === attachment.module_id);

      return `${attachment.title} (${mod.title})`;
    }

    return (
      <>
        <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
          <td className="text-xs p-2">{campaign.subject}</td>
          <td className="text-xs p-2 truncate">{campaign.text}</td>
          <td className="text-xs p-2">{campaign.unlock_time}</td>
          <td className="text-xs p-2">
            {attachmentName(campaign.attachment_id)}
          </td>
          <td className="p-2 text-right">
            <DeleteCampaignButton campaign={campaign}/>
          </td>
        </tr>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const modules = state
    .modules
    .data
    .filter(m => m.course_id === ownProps.campaign.course_id);

  const attachments = modules
    .map(m => m.attachments)
    .flat();

  return { attachments, modules };
};

export default connect(mapStateToProps)(Campaign);
