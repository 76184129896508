import qs from 'qs';
import { toast } from 'react-toastify';
import {
  CAMPAIGNS_SUCCESS,
  CAMPAIGN_SUCCESS,
  CAMPAIGN_DELETE,
  CAMPAIGN_UPDATE,
} from '../constants/actions';

export function loadCampaigns(filters = {}) {
  return (dispatch) => {
    const query = qs.stringify(filters, { addQueryPrefix: true });

    window.messagesApi.get(`/v1/admin/campaigns${query}`)
      .then((response) => {
        dispatch({
          type: CAMPAIGNS_SUCCESS,
          data: response.data,
          totalCount: parseInt(response.headers['x-total-count']) || 0,
        });
      })
      .catch((response) => {
        toast.error('Could not fetch campaigns');
      });
  };
}

export function createCampaign(data) {
  return (dispatch) => {
    window.messagesApi.post('/v1/admin/campaigns', data)
      .then((response) => {
        dispatch({
          type: CAMPAIGN_SUCCESS,
          data: response.data,
        });

        toast.success('Successfully created campaign');
        const { uuid } = response.data;

        if (data.audio) {
          const formData = new FormData();
          formData.append('file', data.audio);

          window.messagesApi.post(`/v1/admin/campaigns/${uuid}/attachment`, formData)
            .then((response) => {
              dispatch({
                type: CAMPAIGN_SUCCESS,
                data: response.data,
              });

              toast.success('Successfully attached audio');
            }).catch((e) => toast.error('Could not attach audio'));
        }

        if (data.image) {
          const formData = new FormData();
          formData.append('file', data.image);

          window.messagesApi.post(`/v1/admin/campaigns/${uuid}/attachment`, formData)
            .then((response) => {
              dispatch({
                type: CAMPAIGN_SUCCESS,
                data: response.data,
              });

              toast.success('Successfully attached image');
            }).catch((e) => toast.error('Could not attach image'));
        }
      })
      .catch((response) => {
        toast.error('Could not create message');
      });
  };
}

const deleteCampaignAttachment = (uuid) => async (dispatch) => {
  try {
    const response = await window.messagesApi.delete(`/v1/admin/attachments/${uuid}`);

    if (response.status !== 204) {
      throw Error('Wrong status code');
    }

    toast.success('Successfully deleted attachment');

    // TODO: Update the UI
  } catch (e) {
    toast.error('Could not delete attachment');
  }
};

// Delete a campaign by given UUID.
const deleteCampaign = (uuid) => async (dispatch) => {
  try {
    const response = await window.messagesApi
      .delete(`/v1/admin/campaigns/${uuid}`);

    if (response.status !== 204) {
      throw Error('Wrong status code');
    }

    dispatch({ type: CAMPAIGN_DELETE, uuid });
  } catch (e) {
    toast.error('Could not delete campaign.');
  }
};

// Delete a campaign by given UUID.
const updateCampaign = (uuid, data) => async (dispatch) => {
  try {
    const response = await window.messagesApi
      .patch(`/v1/admin/campaigns/${uuid}`, data);

    if (response.status !== 200) {
      throw Error('Wrong status code received');
    }

    dispatch({ type: CAMPAIGN_UPDATE, data: response.data });
    toast.success('Updated campaign');

    if (data.audio) {
      const formData = new FormData();
      formData.append('file', data.audio);

      window.messagesApi.post(`/v1/admin/campaigns/${uuid}/attachment`, formData)
        .then((response) => {
          dispatch({
            type: CAMPAIGN_SUCCESS,
            data: response.data,
          });

          toast.success('Successfully attached audio');
        }).catch((e) => toast.error('Could not attach audio'));
    }

    if (data.image) {
      const formData = new FormData();
      formData.append('file', data.image);

      window.messagesApi.post(`/v1/admin/campaigns/${uuid}/attachment`, formData)
        .then((response) => {
          dispatch({
            type: CAMPAIGN_SUCCESS,
            data: response.data,
          });

          toast.success('Successfully attached image');
        }).catch((e) => toast.error('Could not attach image'));
    }
  } catch (e) {
    console.warn(e);
    toast.error('Could not update campaign.');
  }
};

export { updateCampaign, deleteCampaign, deleteCampaignAttachment };
