import React from 'react'
import { Link } from 'react-router-dom';

import DeleteCourseButton from './DeleteCourseButton';
import EditCourseButton from './EditCourseButton';
import UploadCourseCoverButton from './UploadCourseCoverButton';

class Table extends React.Component {
  render() {
    return (
      <table className="min-w-max border shadow w-full table-fixed">
        <thead>
          <tr className="bg-gray-600 text-gray-100 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Title</th>
            <th className="py-3 px-6 text-left">Domain</th>
            <th className="py-3 px-6 text-left">Description</th>
            <th className="py-3 px-6 text-right">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {
            this.props.courses.data.map(course => (
              <tr
                key={course.uuid}
                className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <Link to={"/courses/" + course.uuid} className="underline">
                    {course.title}
                  </Link>
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {course.domain}
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap truncate">
                  {course.description}
                </td>
                <td className="py-3 px-6 text-right whitespace-nowrap">
                  <EditCourseButton course={course} />
                  <UploadCourseCoverButton course={course} />
                  <DeleteCourseButton course={course} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}

export default Table;
