import { unionBy } from 'lodash';
import {
  MODULES_SUCCESS,
  MODULE_SUCCESS,
  MODULE_DELETE,
  ATTACHMENT_CREATE,
  ATTACHMENT_DELETE,
  ATTACHMENT_UPLOAD,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODULES_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, (m) => m.uuid),
      };

    case MODULE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data],
      };

    case MODULE_DELETE:
      return {
        ...state,
        data: state.data.filter((m) => m.uuid !== action.uuid),
      };

    case ATTACHMENT_CREATE:
    case ATTACHMENT_UPLOAD:
      return {
        ...state,
        data: state.data.map((m) => (
          m.uuid === action.data.module_id
            ? {
              ...m,
              attachments: unionBy([action.data], m.attachments, 'uuid'),
            }
            : m
        )),
      };

    case ATTACHMENT_DELETE:
      return {
        ...state,
        data: state.data.map((m) => (
          {
            ...m,
            attachments: m.attachments.filter((a) => a.uuid !== action.uuid),
          }
        )),
      };

    default:
      return {
        ...state,
      };
  }
};
