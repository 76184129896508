import { toast } from 'react-toastify';
import {
  PRODUCTS_SUCCESS,
  PRODUCT_SUCCESS,
  PRODUCT_DELETE,
} from '../constants/actions';

export function loadProducts() {
  return (dispatch) => {
    window.paymentsApi.get('/v1/admin/products')
      .then((response) => {
        dispatch({ type: PRODUCTS_SUCCESS, data: response.data });
      })
      .catch((response) => {
        toast.error('Could not fetch products. API down?!');
      });
  };
}

export function createProduct(payload) {
  return (dispatch) => {
    window.paymentsApi.post('/v1/admin/products', payload)
      .then((response) => {
        dispatch({ type: PRODUCT_SUCCESS, data: response.data });
        toast.success('Successfully created product.');
      })
      .catch((response) => {
        toast.error('Could not create product!');
      });
  };
}

export function updateProduct(uuid, payload) {
  return (dispatch) => {
    window.paymentsApi.patch(`/v1/admin/products/${uuid}`, payload)
      .then((response) => {
        dispatch({ type: PRODUCT_SUCCESS, data: response.data });
        toast.success('Successfully updated product.');
      })
      .catch((response) => {
        toast.error('Could not update product!');
      });
  };
}

export function deleteProduct(uuid) {
  return (dispatch) => {
    window.paymentsApi.delete(`/v1/admin/products/${uuid}`)
      .then((response) => {
        dispatch({ type: PRODUCT_DELETE, uuid });
      })
      .catch((response) => {
        toast.error('Could not delete product!');
      });
  };
}
