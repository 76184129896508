import React from 'react'
import Access from './Access';

class Table extends React.Component {
  render() {
    return (
      <table className="min-w-max border shadow w-full table-fixed">
        <thead>
          <tr className="bg-gray-600 text-gray-100 uppercase text-sm leading-normal">
            <th className="p-2 text-left">E-Mail</th>
            <th className="p-2 text-left">Course</th>
            <th className="p-2 text-left">Type</th>
            <th className="p-2 text-left">Created At</th>
            <th className="p-2 text-right">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {
            this.props.accesses.data.map(access => (
              <Access key={access.uuid} access={access} />
            ))
          }
        </tbody>
      </table>
    );
  }
}

export default Table;
