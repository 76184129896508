import { unionBy } from 'lodash';
import {
  PRODUCTS_SUCCESS,
  PRODUCT_SUCCESS,
  PRODUCT_DELETE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    case PRODUCT_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, [action.data], (c) => c.uuid),
      };

    case PRODUCT_DELETE:
      return {
        ...state,
        data: state.data.filter((p) => p.uuid !== action.uuid),
      };

    default:
      return { ...state };
  }
};
