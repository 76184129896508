import { unionBy } from 'lodash';
import {
  PROFILES_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_CREATE_SUCCESS,
  PROFILE_DELETE_SUCCESS,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILES_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, (p) => p.uuid),
      };

    case PROFILE_CREATE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.data],
      };

    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        data: state.data.map((p) => {
          if (p.uuid === action.data.uuid) {
            return action.data;
          }

          return p;
        }),
      };

    case PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        data: state.data.filter((p) => p.uuid !== action.data),
      };

    default:
      return {
        ...state,
      };
  }
};
