import { unionBy } from 'lodash';
import {
  COURSES_SUCCESS,
  COURSE_SUCCESS,
  COURSE_DELETE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COURSES_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, (c) => c.uuid),
      };

    case COURSE_SUCCESS:
      return {
        ...state,
        data: unionBy([action.data], state.data, (c) => c.uuid),
      };

    case COURSE_DELETE:
      return {
        ...state,
        data: state.data.filter((c) => c.uuid !== action.uuid),
      };

    default:
      return {
        ...state,
      };
  }
};
