import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Menu from '../menu';
import Dashboard from '../dashboard';
import Courses from '../courses';
import Course from '../course';
import Accesses from '../accesses';
import BulkEmail from '../bulk_email';
import User from '../user';
import Products from '../products';
import Payments from '../payments';
import Messages from '../messages';
import Campaigns from '../campaigns';
import NotFound from '../404';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class PrivateApp extends React.Component {
  render() {
    return(
      <>
        <Menu />
        <ToastContainer />
        <div className="flex flex-row px-5 mb-16 font-mono">
          <div className="hidden sm:block w-16 md:w-48 flex-none"></div>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/users/:id" component={User} />
            <Route path="/users/new" component={User} />
            <Route exact path="/courses" component={Courses} />
            <Route path="/courses/:id" component={Course} />
            <Route path="/accesses" component={Accesses} />
            <Route path="/bulk_email" component={BulkEmail} />
            <Route path="/products" component={Products} />
            <Route path="/payments" component={Payments} />
            <Route path="/messages" component={Messages} />
            <Route path="/campaigns" component={Campaigns} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(mapStateToProps)(PrivateApp);
