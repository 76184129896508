import React from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { loadAccesses } from '../../actions/accesses';
import { loadCourses } from '../../actions/courses';

class BulkEmail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      course: null,
      payload: {
        subject: "",
        content: "",
        user_ids: [],
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleCourseSelect = this.handleCourseSelect.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadCourses());
    dispatch(loadAccesses());
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      const response = await window
        .usersApi
        .post(`/v1/admin/bulk_email`, this.state.payload)
      if (response.status === 200) {
        toast.success("Email sent.")
      }
    } catch {
      toast.error("Could not send email.")
    }
  }

  handleContentChange(event) {
    const newPayload = Object.assign(
      {},
      this.state.payload,
      { content: event.target.value}
    )

    this.setState({ payload: newPayload });
  }

  handleSubjectChange(event) {
    const newPayload = Object.assign(
      {},
      this.state.payload,
      { subject: event.target.value}
    )

    this.setState({ payload: newPayload });
  }

  handleCourseSelect(event) {
    const userIds = this.props
      .accesses
      .data
      .filter(a => a.status === "active")
      .filter(a => a.course_id === event.target.value)
      .map(a => a.user_id)

    this.setState({
      course: event.target.value,
      payload: { ...this.state.payload, user_ids: userIds }
    })
  }

  render() {
    return(
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
          Send Bulk Email
        </h1>
        <form
          className="border w-full rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 shadow mb-6"
          onSubmit={this.handleSubmit}>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Course
            </label>

            <select
              name="course"
              defaultValue="none"
              className="mt-1 w-full rounded-md block border py-2 px-4 border-gray-400 focus:border-green-800 outline-none bg-transparent"
              onChange={this.handleCourseSelect}>
                <option disabled hidden value="none">Select</option>
                {
                  this.props.courses.data.map(c => (
                    <option key={c.uuid} value={c.uuid}>
                      {`[${c.domain}] ${c.title}`}
                    </option>
                  ))
                }
            </select>
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Subject
            </label>
            <input type="text"
              required
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              onChange={this.handleSubjectChange} />
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Content
            </label>
            <textarea
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              rows="10"
              onChange={this.handleContentChange}></textarea>
          </div>

          <div className="col-span-6 text-sm bg-orange-100 text-orange-700 border border-orange-200 p-2 rounded">
            <strong>Info:</strong> The email will be sent
            to <strong>{this.state.payload.user_ids.length}</strong> users.
          </div>

          <button
            type="submit"
            className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
            Send Email Now
          </button>
        </form>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    courses: state.courses,
    accesses: state.accesses,
  };
}

export default connect(mapStateToProps)(BulkEmail);

