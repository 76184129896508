import React from 'react';
import Select from 'react-select';

class Filters extends React.Component {
  render() {
    const { onFilterSelect } = this.props;

    const tagOptions = [
      { value: "reifam2021", label: "reifam2021" },
      { value: "reifam2022", label: "reifam2022" },
      { value: "reifam2023", label: "reifam2023" },
      { value: "reifam2024", label: "reifam2024" },
    ]

    const roleOptions = [
      { value: 'admin', label: 'Admin' },
      { value: 'staff', label: 'Staff' },
      { value: 'customer', label: 'Customer' },
    ]

    const typeOptions = [
      { value: 'user', label: 'User' },
      { value: 'machine', label: 'Machine' },
    ]

    const domainOptions = [
      { value: 'com.familienort.my', label: 'com.familienort.my' },
      { value: 'org.reifam.ausbildung', label: 'org.reifam.ausbildung' },
    ];


    const { users, filters } = this.props;

    return (
      <div className="my-3 p-2 flex justify-between items-center">
        <div className="text-sm font-bold rounded-full bg-blue-500 py-2 px-4 text-white">
          {users.totalCount} user{users.totalCount !== 1 ? 's' : ''} found
        </div>
        <form
          className="grid grid-cols-3 gap-5 w-4/6"
          onSubmit={(e) => e.preventDefault() }>

          <div className="flex flex-col">
            <div className="text-xs font-bold">
              Role
            </div>
            <Select
              className="inline-block text-xs"
              options={roleOptions}
              value={roleOptions.find(h => h.value === filters.role)}
              isClearable={true}
              onChange={(s) => onFilterSelect({ role: s ? s.value : undefined })} />
          </div>

          <div className="flex flex-col">
            <div className="text-xs font-bold">
              Type
            </div>
            <Select
              className="inline-block text-xs"
              options={typeOptions}
              value={typeOptions.find(h => h.value === filters.type)}
              isClearable={true}
              onChange={(s) => onFilterSelect({ type: s ? s.value : undefined })} />
          </div>

          <div className="flex flex-col">
            <div className="text-xs font-bold">
              Domain
            </div>
            <Select
              className="inline-block text-xs"
              options={domainOptions}
              value={domainOptions.find(h => h.value === filters.type)}
              isClearable={true}
              onChange={(s) => onFilterSelect({ domain: s ? s.value : undefined })} />
          </div>

          <div className="flex flex-col">
            <div className="text-xs font-bold">
              Tags
            </div>
            <Select
              className="inline-block text-xs"
              isMulti={true}
              onChange={(s) => onFilterSelect({ tags: s.map(s => s ? s.value : undefined).join(",") }) }
              isClearable={true}
              options={tagOptions} />
          </div>

          <div className="flex flex-col">
            <div className="text-xs font-bold">
              E-Mail
            </div>
            <input
              type="text"
              className="inline-block px-2 text-xs border rounded border-gray-400 h-full"
              placeholder="bob@example.com"
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  return;
                }

                onFilterSelect({ email: e.target.value })
              }} />
          </div>

        </form>
      </div>
    )
  }
}

export default Filters;
