import React from 'react';
import image from '../images/logo.png';

const DEFAULT_LOGO_WIDTH = 150;

export default (props) => {
  const width = props.width || DEFAULT_LOGO_WIDTH;

  return (
    <img
      width={width}
      alt='Familienort/REiFAM'
      src={image} />
  );
};
