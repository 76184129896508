import React from 'react'
import { connect } from 'react-redux';
import { TrashIcon } from '@primer/octicons-react';

import { deleteMessage } from '../../actions/messages';

class DeleteButton extends React.Component {
  async handleClick(event) {
    event.preventDefault();

    this.props.dispatch(deleteMessage(this.props.message.uuid));
  }

  render() {
    return(
      <button onClick={this.handleClick.bind(this)}>
        <TrashIcon />
      </button>
    );
  }
}

export default connect()(DeleteButton);
