import qs from 'qs';
import { toast } from 'react-toastify';
import {
  ACCESSES_SUCCESS,
  ACCESS_SUCCESS,
  ACCESS_DELETE,
  ACCESS_UPDATE,
} from '../constants/actions';

export function loadAccesses(filters = {}) {
  return (dispatch) => {
    const query = qs.stringify(filters, { addQueryPrefix: true });

    window.coursesApi.get(`/v1/admin/accesses${query}`)
      .then((response) => {
        dispatch({
          type: ACCESSES_SUCCESS,
          data: response.data,
          totalCount: parseInt(response.headers['x-total-count']) || 0,
        });
      })
      .catch((response) => {
        toast.error('Could not fetch accesses');
      });
  };
}

export function createAccess(payload) {
  return (dispatch) => {
    window.coursesApi.post('/v1/admin/accesses', payload)
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: ACCESS_SUCCESS, data: response.data });
          toast.success('Access successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function deleteAccess(uuid) {
  return (dispatch) => {
    window.coursesApi.delete(`/v1/admin/accesses/${uuid}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: ACCESS_DELETE, uuid });
          toast.success('Access successfully deleted!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function patchAccess(uuid, payload) {
  return (dispatch) => {
    window.coursesApi.patch(`/v1/admin/accesses/${uuid}`, payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: ACCESS_UPDATE, data: response.data });
          toast.success('Access successfully updated!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}
