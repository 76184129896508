import {
  USERS_SUCCESS,
  USER_SUCCESS,
  USER_DELETE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  totalCount: 0,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_SUCCESS:
      return {
        ...state,
        data: action.data,
        totalCount: action.totalCount,
      };

    case USER_SUCCESS:
      return {
        ...state,
        data: state.data.map((u) => {
          if (u.id === action.data.id) {
            return action.data;
          }

          return u;
        }),
      };

    case USER_DELETE:
      return {
        ...state,
        data: state.data.filter((u) => u.id !== action.id),
      };

    default:
      return {
        ...state,
      };
  }
};
