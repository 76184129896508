import React from 'react'
import { connect } from 'react-redux';
import { PencilIcon, XIcon } from '@primer/octicons-react';

import Form from './Form';

class EditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { toggled: false }
  }

  render() {
    const { campaign } = this.props;

    return(
      <>
        <button onClick={() => this.setState({ toggled: true })}>
          <PencilIcon />
        </button>
        { this.state.toggled &&
          <div
            onClick={(e) => e.stopPropagation() }
            className="fixed z-10 inset-0 overflow-y-auto bg-gray-700 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded p-5 text-left">
              <div className="flex flex-col">

                <div className="flex justify-between">
                  <h3 className="font-bold mb-5 text-base">
                    Update campaign "{campaign.subject}"
                  </h3>
                  <button onClick={() => this.setState({ toggled: !this.state.toggled }) }>
                    <XIcon />
                  </button>
                </div>

                <Form campaign={campaign} />

              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default connect()(EditButton);
