import React from 'react'
import { connect } from 'react-redux';

import { deleteProduct } from '../../actions/products';

class DeleteButton extends React.Component {
  async handleClick(event) {
    event.preventDefault();

    this.props.dispatch(deleteProduct(this.props.product.uuid));
  }

  render() {
    return(
      <button onClick={this.handleClick.bind(this)}>
        Delete
      </button>
    );
  }
}

export default connect()(DeleteButton);
