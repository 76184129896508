import React from 'react'
import { Link } from 'react-router-dom';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      success: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleEmailChange(ev) {
    this.setState({ email: ev.target.value });
  }

  submitForm(event) {
    event.preventDefault();

    // Do not allow empty emails.
    if (!this.state.email.length) { return; }

    // Store the context.
    const that = this;

    // Start the user reset action in the API.
    window.usersApi.post('/v1/reset_password', { email: this.state.email })
      .then((response) => {
        if (response.status === 202) {
          that.setState({ success: true });
          return Promise.resolve();
        }

        return Promise.reject();
      });
  }

  renderSuccess() {
    return (
      <div className="w-full max-w-xs px-5 bg-green-100 text-green-700 p-4 border border-green-300">
        Sofern dein Account aktiv ist, solltest
        du in wenigen Sekunden eine E-Mail erhalten.
        <div className="mt-4 text-xs">
          <strong>Noch Fragen?</strong> support@starkemuetter.com
        </div>
      </div>
    )
  }

  renderForm() {
    return (
      <div className="w-full max-w-xs px-5">
        <h1 className="text-2xl mb-2">
          Passwort zurücksetzen
        </h1>
        <p className="text-gray-700 text-base">
          Du wirst nach wenigen Sekunden
          eine E-mail erhalten.
        </p>
        <form
          className="pt-6 pb-8 mb-4"
          onSubmit={this.submitForm}>
          <div className="mb-4">
            <label className="block font-bold">
              E-maildresse
            </label>
            <input
              id="email"
              className="border py-2 px-3 w-full"
              placeholder="E-Mail"
              value={this.state.email}
              onChange={this.handleEmailChange}
              required
              type="email" />
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-primary hover:bg-opacity-75 text-white
                               font-bold py-2 px-4 rounded focus:outline-none
                               focus:shadow-outline"
              color="primary"
              type="submit">
              Beantragen
            </button>
            <Link className="inline-block align-baseline font-bold text-sm
                             text-primary hover:opacity-75" to="/">
              Zurück
            </Link>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return this.state.success ? this.renderSuccess() : this.renderForm()
  }
}

export default Form;
