import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authByPassword } from '../../actions/auth';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null
    };

    this.authenticate = this.authenticate.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value })
  }

  authenticate(event) {
    event.preventDefault();

    const { dispatch } = this.props;

    const email = this.state.email;
    const password = this.state.password;

    dispatch(authByPassword(email, password));
  }

  renderFail() {
    return (
      <div className="bg-orange-100 text-orange-700 border p-3 border-orange-300">
        Passwort oder E-maildresse ist nicht korrekt.
      </div>
    );
  }

  render() {
    return (
      <div className="w-full max-w-xs px-5">
        { this.props.auth.isFailed && this.renderFail() }
        <form
          className="pt-6 pb-8 mb-4"
          onSubmit={this.authenticate}>
          <div className="mb-4">
            <label
              className="block font-bold">
              E-mailadresse
            </label>
            <input
              id="loginEmail"
              type="text"
              className="border py-2 px-3 w-full"
              value={this.state.email}
              placeholder='E-mail'
              onChange={this.handleEmailChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block font-bold">
              Passwort
            </label>
            <input
              id="loginPassword"
              type="password"
              className="border py-2 px-3 w-full"
              value={this.state.password}
              placeholder='Passwort'
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-primary hover:bg-opacity-75 text-white
                               font-bold py-2 px-4 rounded focus:outline-none
                               focus:shadow-outline"
              type="submit">
              Login
            </button>
              <Link className="inline-block align-baseline font-bold text-sm
                               text-primary hover:opacity-75" to="/reset">
              Passwort vergessen?
            </Link>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(LoginForm);
