import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { PencilIcon } from '@primer/octicons-react';

import { loadUsers } from '../../actions/users';

import Filters from './Filters';
import Pagination from '../../components/Pagination';
import BulkActions from './BulkActions';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        role: 'customer',
        type: 'user',
        page: 1,
        email: '',
        domain: '',
      },
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadUsers(this.state.filters));
  }

  // Filter users by given filter.
  setFilter(filter) {
    // Assemble new filter.
    const newFilters = Object.assign(this.state.filters, filter)

    // Set the current state and directly send new user request.
    this.setState(
      { filters: newFilters },
      () => this.props.dispatch(loadUsers(newFilters))
    )
  }

  renderUserTable = () => (
    <table className="min-w-max w-full table-auto text-xs">
      <thead>
        <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
          <th className="py-2 px-4 text-left">ID</th>
          <th className="py-2 px-4 text-left">Last Login</th>
          <th className="py-2 px-4 text-left">E-Mail</th>
          <th className="py-2 px-4 text-left">Type</th>
          <th className="py-2 px-4 text-left">Role</th>
          <th className="py-2 px-4 text-left">Aktionen</th>
        </tr>
      </thead>
      <tbody className="text-xs">
        {
          this.props.users.data.map((user, index) => (
            <tr key={user.id}
                className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">

              <td className="py-2 px-4 text-left whitespace-nowrap">
                { index + 1 }
              </td>

              <td className="py-2 px-4 text-left whitespace-nowrap">
                {moment(user.last_login_at).format('YY-MM-DD HH:mm')}
              </td>

              <td className="py-2 px-4 text-left whitespace-nowrap">
                {user.email}
              </td>

              <td className="py-2 px-4 text-left whitespace-nowrap">
                {user.type}
              </td>

              <td className="py-2 px-4 text-left whitespace-nowrap">
                {user.role}
              </td>

              <td className="py-2 px-4 text-left whitespace-nowrap">
                <Link
                  className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                  to={`/users/${user.uuid}`}>
                  <PencilIcon />
                </Link>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  render = () => (
    <div className="container flex flex-col m-auto">
      <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
        Dashboard
      </h1>

      <Filters
        users={this.props.users}
        filters={this.state.filters}
        onFilterSelect={this.setFilter.bind(this)} />

      { this.renderUserTable() }

      <div className="flex flex-row justify-between items-center">
        <BulkActions filters={this.state.filters} />

        <Pagination
          totalCount={this.props.users.totalCount}
          onPageSelect={this.setFilter.bind(this)}
          currentPage={this.state.filters.page} />

      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users,
  };
}

export default connect(mapStateToProps)(Dashboard);
