import { unionBy } from 'lodash';
import {
  MESSAGES_SUCCESS,
  MESSAGE_SUCCESS,
  MESSAGE_DELETE,
  MESSAGE_REQUEST,
  MESSAGES_REQUEST,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGES_REQUEST:
    case MESSAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
      };

    case MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: unionBy([action.data], state.data, (c) => c.uuid),
      };

    case MESSAGE_DELETE:
      return {
        ...state,
        data: state.data.filter((c) => c.uuid !== action.uuid),
      };

    default:
      return { ...state };
  }
};
