import qs from 'qs';
import { toast } from 'react-toastify';
import {
  COURSE_CAMPAIGNS_SUCCESS,
  COURSE_CAMPAIGN_SUCCESS,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_UPDATE,
} from '../constants/actions';

export function loadCampaigns(filters = {}) {
  return (dispatch) => {
    const query = qs.stringify(filters, { addQueryPrefix: true });

    window.coursesApi.get(`/v1/admin/campaigns${query}`)
      .then((response) => {
        dispatch({
          type: COURSE_CAMPAIGNS_SUCCESS,
          data: response.data,
        });
      })
      .catch((response) => {
        toast.error('Could not fetch campaigns');
      });
  };
}

export function createCampaign(data) {
  return (dispatch) => {
    window.coursesApi.post('/v1/admin/campaigns', data)
      .then((response) => {
        dispatch({
          type: COURSE_CAMPAIGN_SUCCESS,
          data: response.data,
        });

        toast.success('Successfully created campaign');
      })
      .catch((response) => {
        toast.error('Could not create message');
      });
  };
}

// Delete a campaign by given UUID.
const deleteCampaign = (uuid) => async (dispatch) => {
  try {
    const response = await window.coursesApi
      .delete(`/v1/admin/campaigns/${uuid}`);

    if (response.status !== 204) {
      throw Error('Wrong status code');
    }

    dispatch({ type: COURSE_CAMPAIGN_DELETE, uuid });
    toast.success('Successfully deleted campaign');
  } catch (e) {
    toast.error('Could not delete campaign.');
  }
};

// Delete a campaign by given UUID.
const updateCampaign = (uuid, payload) => async (dispatch) => {
  try {
    const response = await window.coursesApi
      .patch(`/v1/admin/campaigns/${uuid}`, payload);

    if (response.status !== 200) {
      throw Error('Wrong status code received');
    }

    dispatch({ type: COURSE_CAMPAIGN_UPDATE, data: response.data });
  } catch (e) {
    console.warn(e);
    toast.error('Could not update campaign.');
  }
};

export { updateCampaign, deleteCampaign };
