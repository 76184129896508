import React from 'react'
import { createCampaign } from '../../actions/courseCampaigns';
import { connect } from 'react-redux';
import Select from 'react-select';

import { PlusIcon, DashIcon } from '@primer/octicons-react';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      subject: '',
      text: '',
      unlock_time: 0,
      attachment_id: null,
      sender_id: null,
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, course } = this.props;

    dispatch(createCampaign({
      subject: this.state.subject,
      text: this.state.text,
      unlock_time: this.state.unlock_time,
      attachment_id: this.state.attachment_id,
      domain: course.domain,
      course_id: course.uuid,
    }));
  }

  attachmentSelectOption (attachment) {
    if (!attachment) {
      return { value: null, label: '???'}
    }

    const { modules } = this.props;

    const label = `${attachment.title} (${modules.find(m => m.uuid === attachment.module_id).title })`

    return { value: attachment.uuid, label }
  }

  render() {
    const { attachments } = this.props;

    return (
      <div className="mb-6">
        <div className="bg-gray-200 text-gray-600 py-3 px-6 font-bold uppercase text-xs leading-normal flex justify-between w-100">
          <h3>New campaign for "{ this.props.course.title}"</h3>
            <div className="outline-none cursor-pointer" onClick={() => { this.setState({ toggled: !this.state.toggled })}}>
              {
                this.state.toggled ?
                  <DashIcon height={24} className="outline-none" /> :
                  <PlusIcon height={24} className="outline-none" />
              }
            </div>
        </div>

        { this.state.toggled &&
          <form
            className="border w-full rounded-md overflow-visible p-5 grid grid-cols-6 gap-6 shadow mb-6"
            onSubmit={this.handleSubmit.bind(this)}>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Subject
              </label>
              <input
                value={this.state.subject}
                onChange={(e) => { this.setState({ subject: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Unlock Time
              </label>
              <input
                onChange={(e) => { this.setState({ unlock_time: ~~e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Attachment
              </label>
              <Select
                isMulti={false}
                className=""
                onChange={(a) => this.setState({ attachment_id: a ? a.value : null })}
                options={attachments.map(a => this.attachmentSelectOption(a))} />
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Text
              </label>
              <textarea
                onChange={(e) => { this.setState({ text: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"></textarea>
            </div>

            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>

          </form>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const modules = state
    .modules
    .data
    .filter(m => m.course_id === ownProps.course.uuid);

  const attachments = modules
    .map(m => m.attachments)
    .flat();

  return { attachments, modules };
};

export default connect(mapStateToProps)(Form);
