import React from 'react'
import { connect } from 'react-redux';
import { createProduct, updateProduct } from '../../actions/products';
import { ThreeBarsIcon } from '@primer/octicons-react';

import TestBuyButton from './TestBuyButton';
import DeleteButton from './DeleteButton';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      stripe_id: "",
      shopify_id: "",
      price_amount: 0,
      price_currency: "",
      discount: 0,
      success_url: "",
      cancel_url: "",
      toggled: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.props.product == null) {
      this.props.dispatch(createProduct(this.state));
    } else {
      this.props.dispatch(updateProduct(this.props.product.uuid, this.state));
    }
  }

  componentDidMount() {
    if (this.props.product === null) { return }

    this.setState({
      name: this.props.product.name,
      description: this.props.product.description,
      stripe_id: this.props.product.stripe_id,
      shopify_id: this.props.product.shopify_id,
      price_amount: this.props.product.price_amount,
      price_currency: this.props.product.price_currency,
      discount: this.props.product.discount,
      success_url: this.props.product.success_url,
      cancel_url: this.props.product.cancel_url,
    });
  }

  render() {
    return (
      <div className="border w-full rounded-md overflow-hidden px-4 py-2 mb-6 bg-gray-100 shadow">
        <div className="relative text-right">
          <div
            className="cursor-pointer"
            onClick={() => this.setState({ toggled: !this.state.toggled }) } >
            <ThreeBarsIcon />
          </div>
          { this.state.toggled &&
            <ul className="absolute bg-white rounded text-sm border border-gray-500 right-0">
              <li className="p-2 text-center">
                <TestBuyButton product={this.props.product} />
              </li>
              <hr />
              <li className="p-2 text-center">
                <DeleteButton product={this.props.product} />
              </li>
            </ul>
          }
        </div>
        <form
          className="grid grid-cols-6 gap-6"
          onSubmit={this.handleSubmit.bind(this)}>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Name
            </label>
            <input
              onChange={(e) => { this.setState({ name: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.name}
              name="title"
              type="text" />
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Description
            </label>
            <textarea
              onChange={(e) => { this.setState({ description: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.description}
              name="desription"></textarea>
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Stripe ID
            </label>
            <input
              onChange={(e) => { this.setState({ stripe_id: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.stripe_id}
              name="title"
              type="text" />
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Shopify ID
            </label>
            <input
              onChange={(e) => { this.setState({ shopify_id: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.shopify_id}
              name="title"
              type="text" />
          </div>

          <div className="col-span-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Price
            </label>
            <input
              onChange={(e) => { this.setState({ price_amount: Number(e.target.value) })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.price_amount}
              type="text" />
          </div>

          <div className="col-span-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Currency
            </label>
            <input
              onChange={(e) => { this.setState({ price_currency: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.price_currency}
              type="text" />
          </div>

          <div className="col-span-2">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Discount
            </label>
            <input
              onChange={(e) => { this.setState({ discount: Number(e.target.value) })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.discount}
              type="text" />
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Success URL
            </label>
            <input
              onChange={(e) => { this.setState({ success_url: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.success_url}
              type="text" />
          </div>

          <div className="col-span-6">
            <label className="block text-xs font-bold uppercase text-gray-700">
              Cancel URL
            </label>
            <input
              onChange={(e) => { this.setState({ cancel_url: e.target.value })}}
              className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
              value={this.state.cancel_url}
              type="text" />
          </div>

          <button
            type="submit"
            className="inline-flex col-span-6 md:col-span-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
            { this.props.product ? 'Update' : 'Create' }
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(Form);
