import React from 'react'
import { connect } from 'react-redux';
import { ArrowUpIcon, XIcon } from '@primer/octicons-react';

import { uploadAttachment } from '../../actions/attachments';

class UploadAttachmentButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { toggled: false, file: null }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { file } = this.state;
    const { dispatch, attachment } = this.props;

    const formData = new FormData();
    formData.append("file", file);

    dispatch(uploadAttachment(attachment.uuid, formData));

    this.setState({ toggled: false });

    event.stopPropagation()
  }

  handleFileInput(event) {
    this.setState({ file: event.target.files[0] });
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="outline-none mx-2"
          onClick={() => this.setState({ toggled: !this.state.toggled })}>
          <ArrowUpIcon />
        </button>
        { this.state.toggled &&
          <div
            onClick={(e) => e.stopPropagation()}
            className="fixed z-10 inset-0 overflow-y-auto bg-gray-700 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded p-5 text-left">
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5 text-base">
                  Upload a file for "{this.props.attachment.title}"
                </h3>
                <button onClick={() => this.setState({ toggled: !this.state.toggled }) }>
                  <XIcon />
                </button>
              </div>
              <form onSubmit={this.handleSubmit.bind(this)}>
                <input
                  type="file"
                  onChange={this.handleFileInput.bind(this)} />
                <button
                  type="submit"
                  className="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
                  Upload
                </button>
              </form>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default connect()(UploadAttachmentButton);
