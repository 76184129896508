import React from 'react'
import { connect } from 'react-redux';
import { EyeClosedIcon, EyeIcon } from '@primer/octicons-react';

import { patchAccess } from '../../actions/accesses';

class DeactivateAccessButton extends React.Component {
  handleUpdate(event) {
    event.preventDefault()

    const { dispatch, access } = this.props;

    const newStatus = access.status === "active" ? "inactive" : "active"
    dispatch(patchAccess(access.uuid, { status: newStatus  }));

    event.stopPropagation()
  }

  render() {
    const { access } = this.props;

    return (
      <button
        className="outline-none mx-2"
        onClick={this.handleUpdate.bind(this)}>
        {access.status === "active" ? <EyeClosedIcon /> : <EyeIcon />}
      </button>
    );
  }
}

export default connect()(DeactivateAccessButton);
