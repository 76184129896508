import React from 'react'
import { connect } from 'react-redux';
import { TrashIcon } from '@primer/octicons-react';

import { deleteAttachment } from '../../actions/attachments';

class DeleteAttachmentButton extends React.Component {
  handleDelete(event) {
    event.preventDefault()

    const { dispatch, attachment } = this.props;

    dispatch(deleteAttachment(attachment.uuid));

    event.stopPropagation()
  }

  render() {
    return (
      <button
        className="outline-none mx-2"
        onClick={this.handleDelete.bind(this)}>
        <TrashIcon />
      </button>
    );
  }
}

export default connect()(DeleteAttachmentButton);
