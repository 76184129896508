import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment';

import { loadCampaigns } from '../../actions/campaigns';

import Form from './Form';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

import StatusPill from './StatusPill';

import { MegaphoneIcon } from '@primer/octicons-react';

class Campaigns extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadCampaigns());
  }

  renderCampaigns = () => (
    <table className="min-w-max w-full table-auto text-sm">
      <thead>
        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th className="py-3 px-6 text-left">No.</th>
          <th className="py-3 px-6 text-left">Domain</th>
          <th className="py-3 px-6 text-left">Publish date</th>
          <th className="py-3 px-6 text-left">Subject</th>
          <th className="py-3 px-6 text-left">Status</th>
          <th className="py-3 px-6 text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          this.props.campaigns.data.map((campaign, index) => (
            <tr
              key={campaign.uuid}
              className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
              <td className="p-2 text-xs">{index + 1}</td>
              <td className="p-2 text-xs">{campaign.domain}</td>
              <td className="p-2 text-xs">{moment(campaign.published_at).format('YY-MM-DD HH:mm')}</td>
              <td className="p-2 text-xs">{campaign.subject}</td>
              <td className="p-2 text-xs"><StatusPill campaign={campaign}/></td>
              <td className="p-2 text-right text-xs">
                <EditButton campaign={campaign} />
                <DeleteButton campaign={campaign}/>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )

  render = () => (
    <div className="container flex flex-col m-auto">
      <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
        <MegaphoneIcon size={40} className="mr-5"/>
        Campaigns
      </h1>

      <div>
        <Form />
        { this.renderCampaigns() }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaigns,
  };
}

export default connect(mapStateToProps)(Campaigns);
