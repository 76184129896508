import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import users from './users';
import profiles from './profiles';
import courses from './courses';
import modules from './modules';
import accesses from './accesses';
import products from './products';
import payments from './payments';
import messages from './messages';
import campaigns from './campaigns';
import courseCampaigns from './courseCampaigns';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  users,
  profiles,
  courses,
  modules,
  accesses,
  products,
  payments,
  messages,
  campaigns,
  courseCampaigns,
});
