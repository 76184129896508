import React from 'react'
import { connect } from 'react-redux';
import { PencilIcon, XIcon } from '@primer/octicons-react';

import { updateCourse } from '../../actions/courses';
import Form from './Form';

class EditCourseButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { toggled: false, file: null }

    this.update = this.update.bind(this);
  }

  update(attributes) {
    const { dispatch, course } = this.props;
    dispatch(updateCourse(course.uuid, attributes));
  }

  toggleModal(e) {
    e.stopPropagation();
    this.setState({ toggled: !this.state.toggled })
  }

  render() {
    const { course } = this.props;

    return (
      <React.Fragment>
        <button
          className="outline-none mx-3"
          onClick={(e) => this.toggleModal(e)}>
          <PencilIcon />
        </button>
        { this.state.toggled &&
          <div
            onClick={(e) => e.stopPropagation() }
            className="fixed z-10 inset-0 overflow-y-auto bg-gray-700 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded p-5 text-left">
              <div className="flex justify-between items-start">
                <h3 className="font-bold mb-5 text-base">
                  Update course "{course.title}"
                </h3>
                <button onClick={() => this.setState({ toggled: !this.state.toggled }) }>
                  <XIcon />
                </button>
              </div>
              <Form
                action={this.update}
                course={course}
                toggled={true}/>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default connect()(EditCourseButton);
