import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

class Filters extends React.Component {
  render() {
    const { onFilterSelect } = this.props;
    const { accesses: { totalCount } } = this.props;

    const userOptions = this.props.users.map(u => (
      { value: u.uuid, label: u.email }
    ))

    const courseOptions = this.props.courses.map(c => (
      { value: c.uuid, label: c.title }
    ))

    const typeOptions = [
      { value: 'full', label: 'Full access' },
      { value: 'trial', label: 'Trial access' },
    ];

    return (
      <div className="my-3 p-2 flex justify-between items-center">
        <div className="text-sm font-bold">
          {totalCount} course access{totalCount !== 1 ? 'es' : ''} found
        </div>
        <form
          className="grid grid-cols-3 gap-5 w-3/6"
          onSubmit={(e) => e.preventDefault() }>

          <Select
            className="inline-block text-xs"
            options={userOptions}
            isClearable={true}
            onChange={(u) => onFilterSelect({ user_id: u ? u.value : undefined })} />

          <Select
            className="inline-block text-xs"
            options={courseOptions}
            isClearable={true}
            onChange={(c) => onFilterSelect({ course_id: c ? c.value : undefined })} />

          <Select
            className="inline-block text-xs"
            options={typeOptions}
            isClearable={true}
            onChange={(t) => onFilterSelect({ type: t ? t.value : undefined })} />

        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.data,
    courses: state.courses.data,
    accesses: state.accesses,
  }
}

export default connect(mapStateToProps)(Filters);
