import React from 'react'
import { deleteUser } from '../../actions/users';

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);

    this.deleteUser = this.deleteUser.bind(this);
  }

  deleteUser(event) {
    event.preventDefault();

    const { dispatch, user } = this.props;

    dispatch(deleteUser(user.uuid));
  }

  render() {
    return (
      <button
        onClick={this.deleteUser}
        className="inline-flex col-start-1 col-end-7 md:col-start-6 md:col-end-7 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none">
        Delete
      </button>
    )
  }
}

export default DeleteButton;
