import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DeleteAccessButton from './DeleteAccessButton';
import DeactivateAccessButton from './DeactivateAccessButton';

class Access extends React.Component {
  render() {
    const { access, user, course } = this.props;

    return (
      <>
        <tr
          className={`border-b border-gray-200 bg-gray-50
                     hover:bg-gray-100 ${access.status === "inactive" && "opacity-50"}`}>
          <td className="text-xs p-2">
            <Link to={`users/${user.id}`} className="underline">
              {user.email}
            </Link>
          </td>
          <td className="text-xs p-2">
            {course.title}
          </td>
          <td className="text-xs p-2">
            {access.type}
          </td>
          <td className="text-xs p-2">
            {access.created_at}
          </td>
          <td className="text-right p-2">
            <DeactivateAccessButton access={access} />
            <DeleteAccessButton access={access} />
          </td>
        </tr>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users.data.find(u => u.uuid === ownProps.access.user_id) || {},
    course: state.courses.data.find(c => c.uuid === ownProps.access.course_id) || {}
  }
}

export default connect(mapStateToProps)(Access);
