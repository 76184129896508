import { toast } from 'react-toastify';
import {
  ATTACHMENT_CREATE,
  ATTACHMENT_DELETE,
  ATTACHMENT_UPLOAD,
} from '../constants/actions';

export function createAttachment(payload) {
  return (dispatch) => {
    window.coursesApi.post('/v1/admin/attachments', payload)
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: ATTACHMENT_CREATE, data: response.data });
          toast.success('Attachment successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function uploadAttachment(id, formData) {
  return (dispatch) => {
    window.coursesApi.post(`/v1/admin/attachments/${id}/upload`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: ATTACHMENT_UPLOAD, data: response.data });
          toast.success('Attachment successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function deleteAttachment(uuid) {
  return (dispatch) => {
    window.coursesApi.delete(`/v1/admin/attachments/${uuid}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: ATTACHMENT_DELETE, uuid });
          toast.success('Attachment successfully deleted!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}
