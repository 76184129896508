import React from 'react'
import { connect } from 'react-redux';
import Form from './Form';
import Table from './Table';

import { loadCourses, createCourse } from '../../actions/courses';

class Courses extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadCourses());
  }

  create(attributes) {
    this.props.dispatch(createCourse(attributes));
  }

  render() {
    return(
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
          Courses
        </h1>
        <Form action={this.create} />
        <Table {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    courses: state.courses,
  };
}

export default connect(mapStateToProps)(Courses);
