import React from 'react'
import { connect } from 'react-redux';
import DeleteModuleButton from './DeleteModuleButton';
import UploadModuleCoverButton from './UploadModuleCoverButton';
import Attachments from './Attachments';
import AttachmentForm from './AttachmentForm';

class Module extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    }
  }

  render() {
    const { module } = this.props;

    return (
      <>
        <tr
          onClick={() => this.setState({ toggled: !this.state.toggled }) }
          className="border-b cursor-pointer border-gray-200 bg-gray-50 hover:bg-gray-100">
          <td className="text-xs p-2">{module.title}</td>
          <td className="text-xs p-2 truncate">{module.description}</td>
          <td className="text-xs p-2">{module.unlock_time}</td>
          <td className="p-2 text-right">
            <UploadModuleCoverButton module={module} />
            <DeleteModuleButton module={module}/>
          </td>
        </tr>
        {
          this.state.toggled &&
            <tr>
              <td colSpan="4" className="p-6">
                <AttachmentForm module={module} />
                <Attachments attachments={module.attachments || []} />
              </td>
            </tr>
        }
      </>
    );
  }
}

export default connect()(Module);
