import {
  ACCESSES_SUCCESS,
  ACCESS_SUCCESS,
  ACCESS_DELETE,
  ACCESS_UPDATE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  totalCount: 0,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCESSES_SUCCESS:
      return {
        ...state,
        totalCount: action.totalCount,
        data: action.data,
      };

    case ACCESS_SUCCESS:
      return {
        ...state,
        data: [action.data, ...state.data],
      };

    case ACCESS_DELETE:
      return {
        ...state,
        data: state.data.filter((a) => a.uuid !== action.uuid),
      };

    case ACCESS_UPDATE:
      return {
        ...state,
        data: state.data.map((a) => {
          if (a.uuid === action.data.uuid) {
            return action.data;
          }

          return a;
        }),
      };

    default:
      return {
        ...state,
      };
  }
};
