import React from 'react'
import { connect } from 'react-redux';
import { createMessage } from '../../actions/messages';
import Select from 'react-select';

const domainOptions = [
  { value: 'org.reifam.ausbildung', label: 'org.reifam.ausbildung' },
  { value: 'org.reifam.admin', label: 'org.reifam.admin' },
  { value: 'com.familienort.my', label: 'com.familienort.my' },
]

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      text: "",
      recipient: "",
      domain: "",
      audio: null,
      image: null
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.dispatch(createMessage(this.state));
  }

  render() {
    return (
      <form
        className="border w-full rounded-md overflow-hidden p-5 shadow mb-6 grid grid-cols-2 gap-4"
        onSubmit={this.handleSubmit.bind(this)}>

        <div className="col-span-2">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Subject
          </label>
          <input
            type="text"
            onChange={(e) => { this.setState({ subject: e.target.value })}}
            className="my-2 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-2">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Text
          </label>
          <textarea
            onChange={(e) => { this.setState({ text: e.target.value })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none">
          </textarea>
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Domain
          </label>
          <Select
            className="text-xs"
            options={domainOptions}
            isClearable={false}
            onChange={(s) => this.setState({ domain: s.value })} />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Recipient
          </label>
          <input
            type="text"
            onChange={(e) => { this.setState({ recipient: e.target.value })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Audio
          </label>
          <input
            type="file"
            onChange={(e) => { this.setState({ audio: e.target.files[0] })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-1">
          <label className="block text-xs font-bold uppercase text-gray-700">
            Image
          </label>
          <input
            type="file"
            onChange={(e) => { this.setState({ image: e.target.files[0] })}}
            className="mt-1 w-full bg-white rounded-md block sm:text-sm border mb-2 py-2 px-4 border-gray-400 focus:border-green-800 outline-none" />
        </div>

        <div className="col-span-2">
          <button
            type="submit"
            className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
            Send
          </button>
        </div>
      </form>
    );
  }
}

export default connect()(Form);
