import React from 'react'
import DeleteAttachmentButton from './DeleteAttachmentButton';
import UploadAttachmentButton from './UploadAttachmentButton';

class Attachments extends React.Component {
  render() {
    return (
      <table className="min-w-max border shadow w-full table-fixed">
        <thead>
          <tr className="bg-gray-600 text-gray-100 uppercase text-xs leading-normal">
            <th className="p-2 text-left">Primary</th>
            <th className="p-2 text-left">Title</th>
            <th className="p-2 text-left">Type</th>
            <th className="p-2 text-left">Category</th>
            <th className="p-2 text-left">Source</th>
            <th className="p-2 text-right">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {
            this.props.attachments.map((a) => (
              <tr
                key={a.uuid}
                className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                <td className="text-xs p-2">{a.primary}</td>
                <td className="text-xs p-2 font-bold">{a.title}</td>
                <td className="text-xs p-2">{a.type}</td>
                <td className="text-xs p-2">{a.category}</td>
                <td className="text-xs p-2">
                  <input
                    readOnly
                    type="text"
                    className="w-full rounded-md block text-xs select-none border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
                    value={!!a.source ? a.source : '-' } />
                </td>
                <td className="text-xs p-2 text-right">
                  <UploadAttachmentButton attachment={a} />
                  <DeleteAttachmentButton attachment={a}/>
                </td>
              </tr>
            ))
          }
          <tr></tr>
        </tbody>
      </table>
    );
  }
}

export default Attachments;
