import { toast } from 'react-toastify';
import {
  MODULES_SUCCESS,
  MODULE_SUCCESS,
  MODULE_DELETE,
} from '../constants/actions';

export function loadModules(course_id) {
  return (dispatch) => {
    window.coursesApi.get(`/v1/admin/modules?course_id=${course_id}`)
      .then((response) => {
        dispatch({ type: MODULES_SUCCESS, data: response.data });
      })
      .catch((response) => {
        toast.error('Could not fetch modules');
      });
  };
}

export function createModule(payload) {
  return (dispatch) => {
    window.coursesApi.post('/v1/admin/modules', payload)
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: MODULE_SUCCESS, data: response.data });
          toast.success('Course successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function deleteModule(uuid) {
  return (dispatch) => {
    window.coursesApi.delete(`/v1/admin/modules/${uuid}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: MODULE_DELETE, uuid });
          toast.success('Course successfully deleted!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function uploadModuleCover(id, formData) {
  return (dispatch) => {
    window.coursesApi.post(`/v1/admin/modules/${id}/cover`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: MODULE_SUCCESS, data: response.data });
          toast.success('Cover successfully attached!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}
