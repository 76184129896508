import React from 'react';

class Pagination extends React.Component {
  setPage(page) {
    this.props.onPageSelect({ page });
  }

  renderPageLink(page) {
    if (page === this.props.currentPage || page === "...") {
      return (
        <div className="px-3" key={page}>
          {page}
        </div>
      )
    }

    return (
      <div
        onClick={() => this.setPage(page)}
        key={page}
        className="px-3 underline cursor-pointer">
        { page }
      </div>
    )
  }

  render() {
    const pageCount = Math.trunc(this.props.totalCount / 25) + 1;
    const displayPages = Array.from({ length: pageCount}, (_, i) => i + 1)
                              .filter((i) => i == 1 || Math.abs(i - this.props.currentPage) < 3 || i == pageCount);

    // Add a '...' indicator
    for (let i = 1; i < displayPages.length; i++) {
      if (displayPages[i] - displayPages[i-1] > 1) {
        displayPages.splice(i, 0, '...');
      }
    }

    return (
      <div className="flex flex-row">
        {
          displayPages.map((p) => this.renderPageLink(p))
        }
      </div>
    )
  }
}

export default Pagination;
