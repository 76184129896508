import React from 'react';
import styles from './styles.module.css';
import Logo from '../../components/Logo';

class Spinner extends React.Component {
  render() {
    return(
      <div className={styles.spinner}>
        <Logo />
        <div className={styles.spinnerText}>
          Lade ...
        </div>
      </div>
    );
  }
}

export default Spinner;
