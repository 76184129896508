import React from 'react'
import { toast } from 'react-toastify';

class TestBuyButton extends React.Component {
  async handleClick(event) {
    event.preventDefault();

    const { product } = this.props;

    const payload = {
      product_id: product.uuid,
    }

    window.paymentsApi.post(`/v1/payments`, payload)
      .then((resp) => {
        window.location.replace(resp.data.checkout_url);
      })
      .catch((resp) => {
        toast.error("Could not create checkout session");
      })
  }

  render() {
    return(
      <button onClick={this.handleClick.bind(this)}>
        Test Buy
      </button>
    );
  }
}

export default TestBuyButton;
