import {
  AUTH_REQUESTED,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_DISMISS,
  AUTH_LOGOUT,
} from '../constants/actions';

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  isFailed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_REQUESTED:
      return {
        ...state,
        isAuthenticating: true,
        isFailed: false,
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isFailed: false,
      };

    case AUTH_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        isFailed: true,
      };

    case AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
      };

    case AUTH_DISMISS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
