import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  ThreeBarsIcon, PlusIcon, PersonIcon, PackageIcon, ChecklistIcon, MailIcon,
  StarIcon, CreditCardIcon, MegaphoneIcon, CommentIcon
} from '@primer/octicons-react';

import Logo from '../../components/Logo';
import { logout } from '../../actions/auth';

const menuItems = [
  { name: 'Users', link: '/', icon: <PersonIcon /> },
  { name: 'Courses', link: '/courses', icon: <PackageIcon /> },
  { name: 'Accesses', link: '/accesses', icon: <ChecklistIcon /> },
  { name: 'Products', link: '/products', icon: <StarIcon /> },
  { name: 'Payments', link: '/payments', icon: <CreditCardIcon /> },
  { name: 'Campaigns', link: '/campaigns', icon: <MegaphoneIcon /> },
  { name: 'Messages', link: '/messages', icon: <CommentIcon /> },
  { name: 'Bulk Email', link: '/bulk_email', icon: <MailIcon /> },
  { name: 'New User', link: '/users/new', icon: <PlusIcon /> },
];

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.state = {
      showMenu: false,
    }
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  logout() {
    const { dispatch } = this.props;
    dispatch(logout());
  }

  render() {
    return(
      <div>
        <div className={`flex-col fixed sm:w-16 md:w-48 w-3/4 sm:h-full shadow-md \
                        top-0 bottom-0 justify-center font-mono text-sm \
                        justify-between bg-white z-50 ${this.state.showMenu ? 'flex' : 'hidden' } md:flex`}>
          <div className="w-full h-full flex flex-col">
            <NavLink to="/"
              className="hidden my-2 md:my-5 py-5 px-1 w-full
                         sm:flex sm:flex-col justify-center items-center">
              <Logo width={80}/>
            </NavLink>
            <ul className="w-full h-full md:h-auto flex flex-col py-3 flex-grow">
              {
                menuItems.map(item => (
                  <li key={item.name}>
                    <NavLink exact={true}
                      activeClassName="bg-primary text-white"
                      to={item.link}
                      className="flex hover:bg-primary hover:text-white
                                 items-center px-3 py-2">

                      {item.icon}

                      <div className="ml-4 md:block">
                        { item.name }
                      </div>

                    </NavLink>
                  </li>
                ))
              }
              <li className="flex-grow" />
              <li>
                <div
                  onClick={this.logout}
                  className="w-full cursor-pointer text-red-600 block py-2 text-center">
                  Abmelden
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          onClick={this.showMenu}
          className="fixed h-16 w-16 right-0 bottom-0 p-5 z-50 rounded-full bg-primary text-white
                     m-4 flex align-items justify-center cursor-pointer md:hidden">

          <ThreeBarsIcon height={24} />
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return({
    router: state.router,
    profile: state.profile,
  });
}

export default connect(mapStateToProps)(Menu);
