import React from 'react'

import Form from './form';

class Reset extends React.Component {

  render() {
    return(
      <div className="container flex justify-around items-center flex-col md:flex-row">
        <Form />
      </div>
    );
  }
}

export default Reset;
