import React from 'react'
import { connect } from 'react-redux';

import { loadCampaigns } from '../../actions/courseCampaigns';
import Campaign from './Campaign';

class Campaigns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    }
  }

  componentDidMount() {
    const { dispatch, course } = this.props;

    dispatch(loadCampaigns({ course_id: course.uuid }));
  }

  render() {
    const { campaigns } = this.props;

    return (
      <table className="min-w-max border shadow w-full table-fixed">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
            <th className="p-2 text-left">Subject</th>
            <th className="p-2 text-left">Text</th>
            <th className="p-2 text-left">Unlock Time</th>
            <th className="p-2 text-left">Attachment</th>
            <th className="p-2 text-right">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {
            campaigns.map(c => <Campaign key={c.uuid} campaign={c} />)
          }
        </tbody>
      </table>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    campaigns: state.courseCampaigns.data.filter((c) => (
      c.course_id === ownProps.course.uuid)
    )
  };
}

export default connect(mapStateToProps)(Campaigns);
