import React from 'react'
import { createModule } from '../../actions/modules';
import { connect } from 'react-redux';

import { PlusIcon, DashIcon } from '@primer/octicons-react';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      description: '',
      title: '',
      unlock_time: 0,
      course_id: this.props.course.uuid,
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { title, description, course_id, unlock_time } = this.state;
    this.props.dispatch(createModule({
      title, description, course_id, unlock_time
    }));
  }

  render() {
    return (
      <div className="mb-6">
        <div className="bg-gray-200 text-gray-600 py-3 px-6 font-bold uppercase text-xs leading-normal flex justify-between w-100">
          <h3>New module for "{ this.props.course.title}"</h3>
            <div className="outline-none cursor-pointer" onClick={() => { this.setState({ toggled: !this.state.toggled })}}>
              {
                this.state.toggled ?
                  <DashIcon height={24} className="outline-none" /> :
                  <PlusIcon height={24} className="outline-none" />
              }
            </div>
        </div>

        { this.state.toggled &&
          <form
            className="border w-full rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 shadow mb-6"
            onSubmit={this.handleSubmit.bind(this)}>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Title
              </label>
              <input
                onChange={(e) => { this.setState({ title: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Unlock Time
              </label>
              <input
                onChange={(e) => { this.setState({ unlock_time: ~~e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Description
              </label>
              <textarea
                onChange={(e) => { this.setState({ description: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"></textarea>
            </div>

            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>

          </form>
        }
      </div>
    );
  }
}

export default connect()(Form);
