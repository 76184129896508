import React from 'react'
import { Link } from 'react-router-dom';

import Logo from '../../images/logo.png';

export default () => (
  <div className="container m-auto py-10 flex flex-col
                  justify-center items-center">
    <img src={Logo} alt="Starke Mütter" className="max-w-100 w-500 mb-5"/>
    <h1 className="text-5xl">
      404
    </h1>
    <p className="text-xl text-center">
      Die gesuchte Seite existiert leider nicht.<br />
      <Link to="/" className="text-primary underline">Hier</Link> geht es zurück
      zur Startseite.
    </p>
  </div>
);
