import React from 'react'
import { createAttachment } from '../../actions/attachments';
import { connect } from 'react-redux';

import { PlusIcon, DashIcon } from '@primer/octicons-react';

class AttachmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      title: '',
      type: '',
      category: '',
      module_id: this.props.module.uuid,
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch, module } = this.props

    // TODO
    const payload = {
      module_id: module.uuid,
      type: this.state.type,
      title: this.state.title,
      category: this.state.category,
      source: this.state.source,
    }

    dispatch(createAttachment(payload))
  }

  render() {
    return (
      <div className="mb-6">
        <div className="bg-gray-600 text-gray-100 p-2 font-bold uppercase text-xs leading-normal flex justify-between w-100">
          <h3>New attachment for "{this.props.module.title}"</h3>
            <div className="outline-none cursor-pointer" onClick={() => { this.setState({ toggled: !this.state.toggled })}}>
              {
                this.state.toggled ?
                  <DashIcon height={24} className="outline-none" /> :
                  <PlusIcon height={24} className="outline-none" />
              }
            </div>
        </div>

        { this.state.toggled &&
          <form
            className="border w-full rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 shadow mb-6"
            onSubmit={this.handleSubmit.bind(this)}>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Title
              </label>
              <input
                onChange={(e) => { this.setState({ title: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Type
              </label>
              <select
                onChange={(e) => { this.setState({ type: e.target.value })}}
                className="mt-1 w-full bg-white rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                name="type">
                <option value="download">download</option>
                <option value="video">video</option>
                <option value="link">link</option>
                <option value="audio">audio</option>
                <option value="text">text</option>
              </select>
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Category
              </label>
              <input
                onChange={(e) => { this.setState({ category: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Source
              </label>
              <input
                onChange={(e) => { this.setState({ source: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>

          </form>
        }
      </div>
    );
  }
}

export default connect()(AttachmentForm);
