import React from 'react'
import { connect } from 'react-redux';
import { TrashIcon } from '@primer/octicons-react';

import { deleteCampaign } from '../../actions/courseCampaigns';

class DeleteCampaignButton extends React.Component {
  handleDelete(event) {
    event.preventDefault()

    const { dispatch, campaign } = this.props;

    dispatch(deleteCampaign(campaign.uuid));

    event.stopPropagation()
  }

  render() {
    return (
      <button
        className="outline-none"
        onClick={this.handleDelete.bind(this)}>
        <TrashIcon />
      </button>
    );
  }
}

export default connect()(DeleteCampaignButton);
