import qs from 'qs';
import { toast } from 'react-toastify';
import {
  USERS_SUCCESS,
  USER_SUCCESS,
  USER_DELETE,
} from '../constants/actions';

export function loadUsers(filters = {}) {
  return (dispatch) => {
    // Stringify the uuid array.
    const query = qs.stringify(filters, { addQueryPrefix: true });

    // Call the club api to find the users.
    window.usersApi.get(`/v1/admin/users${query}`)
      .then((response) => {
        dispatch({
          type: USERS_SUCCESS,
          data: response.data,
          totalCount: parseInt(response.headers['x-total-count']) || 0,
        });
      })
      .catch((response) => {
        // TODO
      });
  };
}

export function fetchUser(uuid) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.get(`/v1/users/${uuid}.json`)
      .then((response) => {
        // TODO
      })
      .catch((response) => {
        // TODO
      });
  };
}

export function updateUser(id, data) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.patch(`/v1/admin/users/${id}`, data)
      .then((response) => {
        if (response.status === 200) {
          toast.success('User erfolgreich gespeichert!');
          dispatch({ type: USER_SUCCESS, data: response.data });
        } else {
          const { message } = response.data;
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(`Error: ${message}`);
      });
  };
}

export function createUser(data) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.post('/v1/admin/users', data)
      .then((response) => {
        if (response.status === 201) {
          toast.success('User erfolgreich gespeichert!');
          dispatch({ type: USER_SUCCESS, data: response.data });
        } else {
          const { message } = response.data;
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(`Error: ${message}`);
      });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.delete(`/v1/admin/users/${id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success('User erfolgreich gelöscht!');
          dispatch({ type: USER_DELETE, id });
        } else {
          toast.error('User nicht gelöscht!');
        }
      })
      .catch((response) => {
        toast.error('User nicht gelöscht!');
      });
  };
}

export function exportToSmallinvoice(id) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.post(`/v1/admin/users/${id}/smallinvoice`)
      .then((response) => {
        if (response.status === 200) {
          toast.success('User erfolgreich exportiert!');
          dispatch({ type: USER_SUCCESS, data: response.data });
        } else {
          const { message } = response.data;
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(`Error: ${message}`);
      });
  };
}
