import { toast } from 'react-toastify';
import {
  PAYMENTS_SUCCESS,
} from '../constants/actions';

export function loadPayments() {
  return (dispatch) => {
    window.paymentsApi.get('/v1/admin/payments')
      .then((response) => {
        dispatch({ type: PAYMENTS_SUCCESS, data: response.data });
      })
      .catch((response) => {
        toast.error('Could not fetch payments. API down?!');
      });
  };
}
