import React from 'react'
import { connect } from 'react-redux';
import DeleteButton from './DeleteButton';
import ExportToSmallinvoiceButton from './ExportToSmallinvoiceButton';
import Profiles from './Profiles';

import { loadUsers, updateUser, createUser } from '../../actions/users';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { user: props.user }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (!user) {
      dispatch(loadUsers());
    }
  }

  handleUserChange(event) {
    const updatedUser = this.state.user;

    updatedUser[event.target.name] = event.target.value;

    this.setState({ user: updatedUser });
  }

  handleAddressChange(event) {
    const updatedUser = this.state.user;

    updatedUser.address[event.target.name] = event.target.value;

    this.setState({ user: updatedUser });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { dispatch } = this.props;
    const { user } = this.state;

    if (!!user.id) {
      dispatch(updateUser(user.uuid, user));
    } else {
      dispatch(createUser(user));
    }
  }

  render() {
    const { user } = this.state;

    return (
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-2xl mb-2">
          User { user.email }
        </h1>
        <form className="w-full" onSubmit={this.handleSubmit}>
          <div className="border rounded-md overflow-hidden p-5 grid mb-6 grid-cols-6 gap-6 shadow">
            <h3 className="font-bold col-span-6">
              Personal information
            </h3>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Gender
              </label>
              <select
                name="gender"
                value={user.gender}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none bg-transparent"
                onChange={this.handleUserChange}>
                <option label=" "></option>
                <option value="M">Mr</option>
                <option value="F">Mrs</option>
              </select>
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Vorname
              </label>
              <input
                value={user.first_name}
                name="first_name"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleUserChange}
                type="text" />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Nachname
              </label>
              <input
                value={user.last_name}
                name="last_name"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleUserChange}
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                E-Mail
              </label>
              <input
                value={user.email}
                name="email"
                onChange={this.handleUserChange}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Mobile Phone
              </label>
              <input
                value={user.phone_mobile}
                name="phone_mobile"
                onChange={this.handleUserChange}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                type="text" />
            </div>
          </div>

          <div className="border rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 mb-6 shadow">
            <h3 className="font-bold col-span-6">
              Technical information
            </h3>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Account Type
              </label>
              <select
                name="type"
                value={user.type}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 bg-transparent outline-none"
                onChange={this.handleUserChange}>
                <option label=" "></option>
                <option value="user">user</option>
                <option value="machine">machine</option>
              </select>
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Account role
              </label>
              <select
                name="role"
                value={user.role}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none bg-transparent"
                onChange={this.handleUserChange}>
                <option label=" "></option>
                <option value="admin">admin</option>
                <option value="staff">staff</option>
                <option value="customer">customer</option>
              </select>
            </div>
          </div>

          <div className="border rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 mb-6 shadow">
            <h3 className="font-bold col-span-6">
              Address
            </h3>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Address Line 1
              </label>
              <input
                value={user.address.address_line_1}
                name="address_line_1"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleAddressChange}
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Address Line 2
              </label>
              <input
                value={user.address.address_line_2}
                name="address_line_2"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleAddressChange}
                type="text" />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Zip Code
              </label>
              <input
                value={user.address.zip_code}
                name="zip_code"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleAddressChange}
                type="text" />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                City
              </label>
              <input
                value={user.address.city}
                name="city"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleAddressChange}
                type="text" />
            </div>

            <div className="col-span-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Country
              </label>
              <select
                name="country"
                value={user.address.country}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none bg-transparent"
                onChange={this.handleAddressChange}>
                <option label=" "></option>
                <option value="DE">DE</option>
                <option value="AT">AT</option>
                <option value="CH">CH</option>
                <option value="LU">LU</option>
                <option value="IT">IT</option>
                <option value="ES">ES</option>
              </select>
            </div>
          </div>

          { !user.id &&
            <div className="border rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 mb-6 shadow">
              <h3 className="font-bold col-span-6">
                Security
              </h3>

              <div className="col-span-3">
                <label className="block text-xs font-bold uppercase text-gray-700">
                  Password
                </label>
                <input
                  value={user.password}
                  name="password"
                  className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                  onChange={this.handleUserChange}
                  type="password" />
              </div>

              <div className="col-span-3">
                <label className="block text-xs font-bold uppercase text-gray-700">
                  Password Confirmation
                </label>
                <input
                  value={user.password_confirmation}
                  name="password_confirmation"
                  className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                  onChange={this.handleUserChange}
                  type="password" />
              </div>
            </div>
          }

          <div className="border rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 mb-6 shadow">
            <h3 className="font-bold col-span-6">
              Meta
            </h3>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Tags
              </label>
              <input
                value={user.tags}
                name="tags"
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                onChange={this.handleUserChange}
                type="text" />
            </div>
          </div>

          <div className="border rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 mb-6 shadow">
            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>
            { user.id &&
              <>
                <ExportToSmallinvoiceButton
                  disabled={!!user.bexio_id}
                  dispatch={this.props.dispatch} user={user} />
                <DeleteButton dispatch={this.props.dispatch} user={user} />
              </>
            }
          </div>
        </form>

        { user.id && <Profiles user={user} /> }

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.users.data.find(u => String(u.uuid) === String(ownProps.match.params.id));

  return {
    user: user || { address: {} },
  };
}

export default connect(mapStateToProps)(Dashboard);
