import React from 'react'
import { connect } from 'react-redux';

import { loadModules } from '../../actions/modules';
import Module from './Module';

class Modules extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
    }
  }

  componentDidMount() {
    const { dispatch, course } = this.props;

    dispatch(loadModules(course.uuid));
  }

  render() {
    const { modules } = this.props;

    return (
      <table className="min-w-max border shadow w-full table-fixed">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
            <th className="p-2 text-left">Title</th>
            <th className="p-2 text-left">Description</th>
            <th className="p-2 text-left">Unlock Time</th>
            <th className="p-2 text-right">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {
            modules.map(m => <Module key={m.uuid} module={m} />)
          }
        </tbody>
      </table>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    modules: state.modules.data.filter((m) => (
      m.course_id === ownProps.course.uuid)
    )
  };
}

export default connect(mapStateToProps)(Modules);
