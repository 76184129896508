import React from 'react';
import { connect } from 'react-redux';
import { loadCourses } from '../../actions/courses';

import Modules from './Modules';
import ModuleForm from './ModuleForm';
import Campaigns from './Campaigns';
import CampaignForm from './CampaignForm';

class Course extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadCourses());
  }

  render() {
    const { course } = this.props;

    if (!course) {
      return (
        <div>
          Seite bitte neu laden.
        </div>
      )
    }

    return (
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold text-2xl mt-5">
          { course.title }
        </h1>

        <div className="mt-2 mb-5">
          <div className="bg-green-200 text-xs inline-block py-1 px-3 rounded-full">
            {course.domain}
          </div>

          <div>{course.created_at}</div>
        </div>

        <p className="bg-gray-100 p-5 rounded mb-8 text-sm">
          { course.description }
        </p>

        <h3 className="text-bold text-xl mb-2">
          Modules
        </h3>

        <div>
          <ModuleForm course={course} />
        </div>

        <Modules course={course} />

        <h3 className="text-bold text-xl mt-5 mb-2">
          Campaigns
        </h3>

        <div>
          <CampaignForm course={course} />
        </div>

        <Campaigns course={course} />

      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    course: state.courses.data.find(c => c.uuid === props.match.params.id),
  }
}

export default connect(mapStateToProps)(Course);
