import React from 'react'
import { connect } from 'react-redux';

import { loadProducts } from '../../actions/products';

import Form from './Form';

class Products extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadProducts());
  }

  renderProducts() {
    return this.props.products.data.map(p => (
      <Form product={p} key={p.uuid} />
    ));

  }

  render = () => (
    <div className="container flex flex-col m-auto">
      <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
        Products
      </h1>

      <div className="grid grid-cols-3 gap-6">
        <Form product={null} />
        { this.renderProducts() }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    products: state.products,
  };
}

export default connect(mapStateToProps)(Products);
