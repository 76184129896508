import React from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';

import { loadProducts } from '../../actions/products';

import { PlusIcon, DashIcon } from '@primer/octicons-react';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
      domain: "",
      product_id: null,
      archived_at: null,
      isPublic: false,
      toggled: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { title, description, domain, product_id, archived_at, isPublic } = this.state;

    this.props.action({
      public: isPublic,
      title,
      description,
      domain,
      product_id,
      archived_at,
    });
  }

  productOptions() {
    return this.props.products.data.map(p => (
      { value: p.uuid, label: p.name }
    ));
  }

  componentDidMount() {
    this.props.dispatch(loadProducts());

    const { course } = this.props;

    if (course === undefined) { return };

    this.setState({
      title: course.title,
      description: course.description,
      domain: course.domain,
      product_id: course.product_id,
      archived_at: course.archived_at,
      isPublic: course.public,
    })
  }

  render() {
    return (
      <div className="mb-6">
        <div className="bg-gray-600 text-gray-100 py-3 px-6 font-bold uppercase text-sm leading-normal flex justify-between w-100">
          <h3>New course</h3>
            <div className="outline-none cursor-pointer" onClick={() => { this.setState({ toggled: !this.state.toggled })}}>
              {
                this.state.toggled ?
                  <DashIcon height={24} className="outline-none" /> :
                  <PlusIcon height={24} className="outline-none" />
              }
            </div>
        </div>

        { (this.state.toggled || this.props.toggled) &&
          <form
            className="border w-full rounded-md overflow-hidden p-5 grid grid-cols-6 gap-6 shadow mb-6"
            onSubmit={this.handleSubmit.bind(this)}>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Title
              </label>
              <input
                onChange={(e) => { this.setState({ title: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                value={this.state.title}
                name="title"
                type="text" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Domain
              </label>
              <input
                onChange={(e) => { this.setState({ domain: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                name="domain"
                value={this.state.domain}
                type="text" />
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Description
              </label>
              <textarea
                onChange={(e) => { this.setState({ description: e.target.value })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                value={this.state.description}
                name="desription"></textarea>
            </div>

            <div className="col-span-6">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Product
              </label>
              <Select
                className="inline-block text-xs w-full"
                isMulti={false}
                onChange={(s) => this.setState({ product_id: s ? s.value : null }) }
                value={this.productOptions().find(o => o.value === this.state.product_id)}
                isClearable={true}
                options={this.productOptions()} />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Archived
              </label>
              <input
                onChange={(e) => { this.setState({ archived_at: e.target.checked ? new Date() : null })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                name="archived_at"
                defaultChecked={!!this.state.archived_at}
                checked={!!this.state.archived_at}
                type="checkbox" />
            </div>

            <div className="col-span-3">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Public
              </label>
              <input
                onChange={(e) => { this.setState({ isPublic: e.target.checked })}}
                className="mt-1 w-full rounded-md block sm:text-sm border py-2 px-4 border-gray-400 focus:border-green-800 outline-none"
                name="isPublic"
                defaultChecked={this.state.isPublic}
                checked={this.state.isPublic}
                type="checkbox" />
            </div>

            <button
              type="submit"
              className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none">
              Save
            </button>

          </form>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    products: state.products
  }
}

export default connect(mapStateToProps)(Form);
