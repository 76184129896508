import { unionBy } from 'lodash';
import {
  COURSE_CAMPAIGNS_SUCCESS,
  COURSE_CAMPAIGN_SUCCESS,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_UPDATE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COURSE_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };

    case COURSE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: unionBy([action.data], state.data, (c) => c.uuid),
      };

    case COURSE_CAMPAIGN_DELETE:
      return {
        ...state,
        data: state.data.filter((c) => c.uuid !== action.uuid),
      };

    case COURSE_CAMPAIGN_UPDATE:
      return {
        ...state,
        data: unionBy([action.data], state.data, (c) => c.uuid),
      };

    default:
      return { ...state };
  }
};
