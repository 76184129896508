import React from 'react'
import { useDispatch } from 'react-redux';

import { updateCampaign } from '../../actions/campaigns';

const colors = {
  'ready': 'bg-red-200',
  'sent': 'bg-green-200',
  'draft': 'bg-gray-200',
}

export default (props) => {
  const dispatch = useDispatch();
  const { status, uuid } = props.campaign;

  const handleClick = () => {
    if (status !== "draft") { return };

    dispatch(updateCampaign(uuid, { status: 'ready' }));
  }

  return(
    <button
      onClick={handleClick}
      className={`inline-block break-normal rounded-full text-center px-2 ${colors[status]}`}>
      { status }
    </button>
  );
}
