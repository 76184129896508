import React from 'react'
import { connect } from 'react-redux';
import { TrashIcon } from '@primer/octicons-react';

import { deleteCampaignAttachment } from '../../actions/campaigns';

const Attachment = ({ dispatch, attachment }) => {
  const handleDelete = (e) => {
    e.preventDefault();

    dispatch(deleteCampaignAttachment(attachment.uuid));
  }

  return(
    <div className="p-2 flex justify-between border m-2">
      <div className="">
        <a href={`${process.env.REACT_APP_FILES_URL}/campaigns/${attachment.uuid}`}>
          <h3 className="font-bold">{attachment.uuid}</h3>
        </a>
        <p>{attachment.media_type}</p>
      </div>


      <button onClick={handleDelete}>
        <TrashIcon />
      </button>
    </div>
  );
};

export default connect()(Attachment);
