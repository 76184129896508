import React from 'react'
import { connect } from 'react-redux';

import { loadMessages } from '../../actions/messages';

import Form from './Form';
import DeleteButton from './DeleteButton';

class Messages extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadMessages());
  }

  renderMessages = () => {
    if (this.props.messages.isFetching) {
      return (
        <div>
          Loading messages ...
        </div>
      )
    }

    return (
      <table className="min-w-max w-full table-auto text-sm">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">No.</th>
            <th className="py-3 px-6 text-left">Subject</th>
            <th className="py-3 px-6 text-left">User</th>
            <th className="py-3 px-6 text-left">Created At</th>
            <th className="py-3 px-6 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.messages.data.map((message, index) => (
              <tr
                key={message.uuid}
                className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100 text-xs">
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{message.subject}</td>
                <td className="p-2">{message.recipient}</td>
                <td className="p-2">{message.created_at}</td>
                <td className="p-2 text-right"><DeleteButton message={message}/></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }

  render = () => (
    <div className="container flex flex-col m-auto">
      <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
        Messages
      </h1>

      <div>
        <Form />
        { this.renderMessages() }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    messages: state.messages,
  };
}

export default connect(mapStateToProps)(Messages);
