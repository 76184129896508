import React from 'react'
import { connect } from 'react-redux';
import { createProfile, loadProfiles } from '../../actions/profiles';
import Profile from './Profile';

class Profiles extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDomainChange = this.handleDomainChange.bind(this);

    this.state = {
      domain: ""
    }
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (!!user) {
      dispatch(loadProfiles(user));
    }
  }

  handleDomainChange(e) {
    this.setState({ domain: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { dispatch, user } = this.props;

    dispatch(createProfile(user.uuid, this.state));
  }

  render() {
    return (
      <div className="border rounded-md overflow-hidden p-5 mb-6 shadow">
        <h3 className="font-bold col-span-6 mb-5">
          Profiles
        </h3>

        <div className="grid grid-cols-3 gap-5">
          <form
            className="border"
            onSubmit={this.handleSubmit}>
            <div className="bg-gray-100 p-2 border-b rounded text-sm font-bold">
              Neues Profil anlegen
            </div>
            <div className="p-2">
              <label className="block text-xs font-bold uppercase text-gray-700">
                Domain
              </label>
              <input
                className="mt-1 mb-2 w-full rounded-md block text-xs border py-1 px-2 border-gray-400 focus:border-green-800 outline-none"
                type="text"
                placeholder="org.reifam.ausbildung"
                name="domain"
                onChange={this.handleDomainChange}
                value={this.state.domain} />
              <input
                className="inline-flex col-start-1 col-end-7 md:col-start-1 md:col-end-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none"
                type="submit" />
            </div>
          </form>
          {
            this.props.profiles.map((p) => (
              <Profile
                profile={p}
                user={this.props.user}
                key={p.uuid}
                dispatch={this.props.dispatch}/>
            ))
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return({
    profiles: state.profiles.data.filter((p) => p.user_id === ownProps.user.uuid),
  });
}

export default connect(mapStateToProps)(Profiles);
