import React from 'react'
import { connect } from 'react-redux';

import { loadPayments } from '../../actions/payments';
import StatusPill from './StatusPill';

class Payments extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadPayments());
  }

  renderPaymentsTable = () => (
    <table className="min-w-max w-full table-auto text-xs">
      <thead>
        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th className="py-3 px-6 text-left">No.</th>
          <th className="py-3 px-6 text-left">Status</th>
          <th className="py-3 px-6 text-left">User</th>
          <th className="py-3 px-6 text-left">Product</th>
          <th className="py-3 px-6 text-left">Provider</th>
          <th className="py-3 px-6 text-left">External ID</th>
        </tr>
      </thead>
      <tbody>
        {
          this.props.payments.data.map((payment, index) => (
            <tr key={index}
                className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">

              <td className="py-3 px-6 text-left whitespace-nowrap">
                { index + 1 }
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
                <StatusPill status={payment.status} />
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
                {payment.user_id}
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
                {payment.product_id}
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
                {payment.provider}
              </td>

              <td className="py-3 px-6 text-left whitespace-nowrap">
                {payment.external_id}
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  render = () => (
    <div className="container flex flex-col m-auto">
      <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
        Payments
      </h1>

      { this.renderPaymentsTable() }

    </div>
  );
}

function mapStateToProps(state) {
  return {
    payments: state.payments,
  };
}

export default connect(mapStateToProps)(Payments);
