import React from 'react'

const colors = {
  'pending': 'bg-orange-200',
  'failed': 'bg-red-200',
  'settled': 'bg-green-200',
  'refunded': 'bg-gray-200',
}

export default (props) => (
  <div className={`break-normal rounded-full text-center px-2 ${colors[props.status]}`}>
    { props.status }
  </div>
)
