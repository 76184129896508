import axios from 'axios';

let isRefreshing = false;
let requestQueue = [];

export default () => {
  window.usersApi = axios.create({
    baseURL: process.env.REACT_APP_USERS_API_URL,
  });

  window.coursesApi = axios.create({
    baseURL: process.env.REACT_APP_COURSES_API_URL,
  });

  window.paymentsApi = axios.create({
    baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
  });

  window.messagesApi = axios.create({
    baseURL: process.env.REACT_APP_MESSAGES_API_URL,
  });

  // Set basic axios defaults.
  axios.defaults.withCredentials = true;

  [
    window.usersApi,
    window.coursesApi,
    window.paymentsApi,
    window.messagesApi,
  ].forEach((api) => {
    // Set axios request interceptor
    api.interceptors.request.use(
      useAccessToken,
      err => Promise.reject(err)
    );

    // Set axios response interceptors
    api.interceptors.response.use(
      response => response,
      handleResponseError(api)
    );
  });
};

const handleResponseError = (api) => {
  return async (error) => {
    const { config, response: { status }} = error;

    // Unless it's an authorization error - return here.
    if (status !== 401) {
      throw new Error(error);
    }

    // If a previous refresh already failed - return here.
    if (config._retry) {
      logout();
      throw new Error(error);
    }

    config._retry = true;

    if (!isRefreshing) {
      try {
        await refreshAuthBundle();
        requestQueue.forEach((r) => r.resolve());
        requestQueue = [];

        return api(config);
      } catch {
        requestQueue.forEach((r) => r.reject());
        requestQueue = [];

        logout();
      }
    }

    return new Promise((resolve, reject) => {
      requestQueue.push({
        resolve: () => {
          resolve(api(config));
        },
        reject: (err) => {
          reject(err);
        }
      });
    })
  }
}

const useAccessToken = (config) => {
  const token = localStorage.getItem("accessToken");

  if (token != null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

// Log the user out of the system.
const logout = () => {
  localStorage.clear();
  window.location.href = '/';
}

// Request a new jwt bundle.
const refreshAuthBundle = async () => {
  isRefreshing = true;

  const refresh_token = localStorage.getItem("refreshToken")
  const refreshURL = `${process.env.REACT_APP_USERS_API_URL}/v1/refresh`

  const response = await axios.post(refreshURL, { refresh_token });

  localStorage.setItem('accessToken', response.data.access_token);
  localStorage.setItem('refreshToken', response.data.refresh_token);

  isRefreshing = false;
}
