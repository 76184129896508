import React from 'react'
import { connect } from 'react-redux';

import { loadAccesses } from '../../actions/accesses';
import { loadCourses } from '../../actions/courses';
import { loadUsers } from '../../actions/users';

import Form from './Form';
import Table from './Table';
import Filters from './Filters';
import Pagination from '../../components/Pagination';

class Accesses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        user_id: undefined,
        course_id: undefined,
        page: 1,
      },
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;

    // Hardcore api calls.
    dispatch(loadAccesses(this.state.filters));
    dispatch(loadUsers({ type: "user" }));
    dispatch(loadCourses());
  }

  setFilter(filter) {
    // Assemble new filter.
    const newFilters = Object.assign(this.state.filters, filter)

    // Set the current state and directly send new user request.
    this.setState(
      { filters: newFilters },
      () => this.props.dispatch(loadAccesses(newFilters))
    )
  }

  render() {
    return(
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-2xl xl:text-3xl 2xl:text-4xl mb-2">
          Accesses
        </h1>
        <Form {...this.props} />
        <Filters onFilterSelect={this.setFilter.bind(this)}/>
        <Table {...this.props} />
        <Pagination
          totalCount={this.props.accesses.totalCount}
          onPageSelect={this.setFilter.bind(this)}
          currentPage={this.state.filters.page} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accesses: state.accesses,
  }
}

export default connect(mapStateToProps)(Accesses);
