import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import Login from '../login'
import NotFound from '../404';
import Reset from '../reset'

class PublicApp extends React.Component {
  render() {
    return(
      <div className="flex justify-center justify-center items-center bg-white h-screen">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/reset" component={Reset} />
          <Route exact path="/" component={Login} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(mapStateToProps)(PublicApp);
