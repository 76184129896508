import React from 'react'
import { exportToSmallinvoice } from '../../actions/users';

class ExportToSmallinvoiceButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    // We are inside a form, but don't want to submit it.
    event.preventDefault();

    const { dispatch, user } = this.props;

    dispatch(exportToSmallinvoice(user.uuid));
  }

  render() {
    if (this.props.disabled) {
      return (
        <button
          onClick={(e) => e.preventDefault()}
          className="inline-flex col-start-1 col-end-7 md:col-start-2 md:col-end-3 justify-center py-2 px-4 border border-blue-300 shadow-sm text-sm font-medium rounded-md text-blue-300 bg-transparent focus:outline-none">
          Bereits exportiert
        </button>
      )
    } else {
      return (
        <button
          onClick={this.handleClick}
          className="inline-flex col-start-1 col-end-7 md:col-start-2 md:col-end-3 justify-center py-2 px-4 border border-blue-500 shadow-sm text-sm font-medium rounded-md text-blue-500 hover:text-white bg-transparent hover:bg-blue-700 focus:outline-none">
          Smallinvoice Export
        </button>
      )
    }
  }
}

export default ExportToSmallinvoiceButton;
