import qs from 'qs';
import { toast } from 'react-toastify';
import {
  MESSAGES_SUCCESS,
  MESSAGE_SUCCESS,
  MESSAGE_DELETE,
  MESSAGE_REQUEST,
  MESSAGES_REQUEST,
} from '../constants/actions';

export function loadMessages(filters = {}) {
  return (dispatch) => {
    dispatch({ type: MESSAGES_REQUEST });

    const query = qs.stringify(filters, { addQueryPrefix: true });

    window.messagesApi.get(`/v1/admin/messages${query}`)
      .then((response) => {
        dispatch({
          type: MESSAGES_SUCCESS,
          data: response.data,
          totalCount: parseInt(response.headers['x-total-count']) || 0,
        });
      })
      .catch((response) => {
        toast.error('Could not fetch messages');
      });
  };
}

export function createMessage(data) {
  return (dispatch) => {
    dispatch({ type: MESSAGE_REQUEST });

    window.messagesApi.post('/v1/admin/messages', data)
      .then((response) => {
        dispatch({
          type: MESSAGE_SUCCESS,
          data: response.data,
        });

        const { uuid } = response.data;

        if (data.audio) {
          const formData = new FormData();
          formData.append('file', data.audio);

          window.messagesApi.post(`/v1/admin/messages/${uuid}/attachment`, formData)
            .then((response) => {
              dispatch({
                type: MESSAGE_SUCCESS,
                data: response.data,
              });

              toast.success('Successfully attached audio');
            }).catch((e) => toast.error('Could not attach audio'));
        }

        if (data.image) {
          const formData = new FormData();
          formData.append('file', data.image);

          window.messagesApi.post(`/v1/admin/messages/${uuid}/attachment`, formData)
            .then((response) => {
              dispatch({
                type: MESSAGE_SUCCESS,
                data: response.data,
              });

              toast.success('Successfully attached image');
            }).catch((e) => toast.error('Could not attach image'));
        }
      })
      .catch((response) => {
        toast.error('Could not create message');
      });
  };
}

// Delete a message by given UUID.
const deleteMessage = (uuid) => async (dispatch) => {
  try {
    const response = await window.messagesApi
      .delete(`/v1/admin/messages/${uuid}`);

    if (response.status !== 204) {
      throw Error('Wrong status code');
    }

    dispatch({ type: MESSAGE_DELETE, uuid });
  } catch (e) {
    toast.error('Could not delete message.');
  }
};

export { deleteMessage };
