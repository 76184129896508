import { toast } from 'react-toastify';
import {
  COURSES_SUCCESS,
  COURSE_SUCCESS,
  COURSE_DELETE,
} from '../constants/actions';

export function loadCourses() {
  return (dispatch) => {
    window.coursesApi.get('/v1/admin/courses')
      .then((response) => {
        dispatch({ type: COURSES_SUCCESS, data: response.data });
      })
      .catch((response) => {
        toast.error('Could not fetch courses');
      });
  };
}

export function createCourse(payload) {
  return (dispatch) => {
    window.coursesApi.post('/v1/admin/courses', payload)
      .then((response) => {
        if (response.status === 201) {
          dispatch({ type: COURSE_SUCCESS, data: response.data });
          toast.success('Course successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function updateCourse(uuid, payload) {
  return (dispatch) => {
    window.coursesApi.patch(`/v1/admin/courses/${uuid}`, payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: COURSE_SUCCESS, data: response.data });
          toast.success('Course successfully updated!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function deleteCourse(uuid) {
  return (dispatch) => {
    window.coursesApi.delete(`/v1/admin/courses/${uuid}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: COURSE_DELETE, uuid });
          toast.success('Course successfully deleted!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}

export function uploadCourseCover(id, formData) {
  return (dispatch) => {
    window.coursesApi.post(`/v1/admin/courses/${id}/cover`, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: COURSE_SUCCESS, data: response.data });
          toast.success('Attachment successfully created!');
        } else {
          toast.error(`Error: ${response.data}`);
        }
      })
      .catch((response) => {
        toast.error(`Error: ${response.data}`);
      });
  };
}
