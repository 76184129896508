import React from 'react'

import Form from './form';

class Login extends React.Component {
  render() {
    return(
      <div className="container flex justify-around items-center flex-col">
        <Form />
      </div>
    );
  }
}

export default Login;
