import React from 'react';
import qs from 'qs';

class BulkActions extends React.Component {
  constructor(props) {
    super(props);

    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadJSON = this.downloadJSON.bind(this);
  }

  async download(type) {
    const { filters } = this.props;
    const query = qs.stringify(filters, { addQueryPrefix: true });

    try {
      const response = await window
        .usersApi
        .get(`/v1/admin/users${query}`, {
          responseType: 'blob',
          method: 'GET',
          headers: {
            Accept: type,
          }
        })

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.'+type.split("/")[1])
      link.target = "_blank";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch {
      // TODO
    }
  }

  downloadCSV(e) {
    e.preventDefault();
    this.download('text/csv');
  }

  downloadJSON(e) {
    e.preventDefault();
    this.download('application/json');
  }

  render() {
    return(
      <div className="py-3 flex text-xs">
        <div className="font-bold">
          Download:
        </div>
        <button href="#"
          onClick={this.downloadCSV}
          className="underline ml-2">
          CSV
        </button>
        <button
          onClick={this.downloadJSON}
          className="underline ml-2">
          JSON
        </button>
      </div>
    )
  }
}

export default BulkActions;
