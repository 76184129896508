import { toast } from 'react-toastify';
import {
  PROFILES_SUCCESS,
  PROFILE_CREATE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_DELETE_SUCCESS,
} from '../constants/actions';

export function loadProfiles(user) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.get(`/v1/admin/users/${user.uuid}/profiles`)
      .then((response) => {
        dispatch({ type: PROFILES_SUCCESS, data: response.data });
      })
      .catch((response) => {
        // TODO
      });
  };
}

export function createProfile(user_id, data) {
  return (dispatch) => {
    // Call the club api to find the users.
    window.usersApi.patch(`/v1/admin/users/${user_id}/profiles/${data.domain}`, data)
      .then((response) => {
        if (response.status === 201) {
          toast.success('Profil erfolgreich erstellt!');
          dispatch({ type: PROFILE_CREATE_SUCCESS, data: response.data });
        } else if (response.status === 200) {
          toast.success('Profil erfolgreich geändert!');
          dispatch({ type: PROFILE_UPDATE_SUCCESS, data: response.data });
        } else {
          const { message } = response.data;
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(`Error: ${message}`);
      });
  };
}

export function deleteProfile(profile) {
  return (dispatch) => {
    // Delete user.
    window.usersApi.delete(`/v1/admin/profiles/${profile.uuid}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Profil erfolgreich gelöscht!');
          dispatch({ type: PROFILE_DELETE_SUCCESS, data: profile.uuid });
        } else {
          const { message } = response.data;
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(`Error: ${message}`);
      });
  };
}
